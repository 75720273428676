* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

.inp::placeholder {
  color: black;

}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.material-wrapper {
  display: flex;
  flex-direction: column;

  article.material-image {
    img {
      width: 300px;
      height: 300px;
    }
  }
}

.login {
  padding: 40px;
  margin-top: 40px;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    background-color: white;
    padding: 35px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;

    .form-control {
      width: 100%;
      margin-top: 1rem;

      .input-label {
        font-size: 22px;
        margin-top: -6px;
      }

      .input {
        font-size: 18px;
        width: 100% !important;
        background-color: #f8f9fa;
        padding: 10px;
        border-radius: 10px;
      }
    }

    .submit {
      margin-top: 3rem;
    }

    .button>button {
      background-color: #295deb !important;
      width: 100% !important;
      color: white;
      font-size: 17px;
    }
  }
}

.profile-account {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto;
  flex-direction: column;

  h3 {
    span {
      margin-left: 5px;
    }
  }
}

// date input
#datetime-local-label {
  font-size: 17px;
  margin-left: -5px;
}

#datetime-local {
  padding: 18px;
}

#date-picker {
  display: flex;
  justify-content: center;
}

div.update-password {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 12px;
  margin: 35px;

  // background-color: white;
  form.password-details {
    padding: 5px;
    padding-top: 10px;
    font-size: 28px;
    width: 100%;

    .current-password {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .enter-current-password {
        width: 30%;
      }
    }

    .new-password {
      display: flex;
      flex-direction: column;
      gap: 9px;
      align-items: flex-start;
      margin-top: 3rem;

      .new-password-input {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .enter-new-password {
          width: 30%;
        }
      }
    }
  }
}

.profile-data {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  .inventory-role {
    color: #4c4b4b;
    font-weight: 500;
    margin-top: 3px;
  }
}

.GenerateSerialBtn {
  background-color: #c2fbd7;
  border-radius: 100px;
  box-shadow: rgba(44, 187, 99, .2) 0 -25px 18px -14px inset, rgba(44, 187, 99, .15) 0 1px 2px, rgba(44, 187, 99, .15) 0 2px 4px, rgba(44, 187, 99, .15) 0 4px 8px, rgba(44, 187, 99, .15) 0 8px 16px, rgba(44, 187, 99, .15) 0 16px 32px;
  color: green;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  padding: 2px 12px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.GenerateSerialBtn:hover {
  box-shadow: rgba(44, 187, 99, .35) 0 -25px 18px -14px inset, rgba(44, 187, 99, .25) 0 1px 2px, rgba(44, 187, 99, .25) 0 2px 4px, rgba(44, 187, 99, .25) 0 4px 8px, rgba(44, 187, 99, .25) 0 8px 16px, rgba(44, 187, 99, .25) 0 16px 32px;
}
.GenerateSerialBtn:active {
  background-color: #4bd77e;
}


.resetBtn {
  align-items: center;
  width: 142px;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 3px 0;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.resetBtn:hover,
.resetBtn:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.resetBtn:hover {
  transform: translateY(-1px);
}

.resetBtn:active {
  background-color: black;
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
